.input {
    display: flex;
    width: 95%;
    position: relative;
    align-items: center;
}

.input__box {
    width: 100%;
    border-radius: 50px;
    padding: 20px 30px;
    font-size: 25px;
    border: none;
    transition: 0.2s;
    box-shadow: inset 0 0 5px black;
}

.input__box:focus {
    box-shadow: 0 0 10px 1000px rgba(0, 0, 0, 0.5);
    outline: none;
}

.input__submit {
    position: absolute;
    width: 50px;
    height: 50px;
    margin: 12px;
    border-radius: 50px;
    right: 0px;
    border: none;
    font-size: 15px;
    background-color: #2f74c0;
    color: white;
    transition: 0.2s all;
    box-shadow: 0 0 10px black;
}

.input__submit:hover {
    background-color: #388ae2;
}

.input__submit:active {
    transform: scale(0.8);
    box-shadow: 0 0 5px black;
}

.container {
    display: flex;
    width: 95%;
    margin-top: 10px;
    justify-content: space-between;
    align-items: flex-start;
}

.todos {
    display: flex;
    width: 47.5%;
    flex-direction: column;
    padding: 15px;
    border-radius: 5px;
    background-color: rgb(50, 195, 205);
}

.todos__heading {
    font-size: 30px;
    color: white;
}

.todos__single {
    display: flex;
    border-radius: 5px;
    padding: 20px;
    margin-top: 15px;
    background-image: url("https://img.freepik.com/free-photo/crumpled-yellow-paper-background-close-up_60487-2390.jpg?size=626&ext=jpg");
    transition: 0.2s;
}

.todos__single:hover {
    box-shadow: 0 0 10px #999;
    transform: scale(1.01);
}

.todos__single--text {
    flex: 1;
    padding: 5px;
    border: none;
    font-size: 20px;
}

.icon {
    margin-left: 10px;
    font-size: 25px;
    cursor: pointer;
}

@media (max-width: 1100px) {
    .todos {
        width: 45%;
    }
}

@media (max-width: 700px) {
    .input {
        width: 95%;
    }
    .todos {
        width: 95%;
        margin-bottom: 10px;
    }
    .container {
        width: 95%;
        flex-direction: column;
    }
}

.remove {
    background-color: rgb(235, 103, 80);
}

.dragactive {
    background-color: rgb(0, 221, 236);
}

.dragcomplete {
    background-color: rgb(255, 38, 0);
}

.drag {
    box-shadow: 0 0 20px black;
}
