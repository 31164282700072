@import url(https://fonts.bunny.net/css?family=advent-pro:300);

.App {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #2f74c0;
    font-family: 'Advent Pro', sans-serif;
}

.heading {
    text-transform: uppercase;
    font-size: 40px;
    margin: 30px 0;
    color: white;
    z-index: 1;
    text-align: center;
}

@media (max-width: 800px) {
    .heading {
        margin: 15px;
        font-size: 35px;
    }
}
